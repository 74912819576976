export const environment = {
  type: 'DEVELOPMENT',
  production: false,
  debugMode: false,
  idleTime: 600,
  timeout: 30,
  base_eru_auth_url: 'https://eruauth.dev.processo.io',
  base_eru_routes_url: 'https://erufunc.dev.processo.io',
  base_url: 'https://eruql.dev.processo.io',
  superSeturl: "https://ss.dev.erutech.io/",
  is_artfine:false,
  ping:2400,
  custom_domain:'processo.io'
};
