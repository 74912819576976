import { CommonModule, NgFor, NgIf } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import {
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { DeleteColumnPopupComponent } from '@processo/modules/dashboard/popup/delete-column-popup/delete-column-popup.component';
import { NotificationService } from '@processo/services/notification.service';
import { AppstateService } from '@processo/shared/state/appstate.service';
import { StateService } from '@processo/shared/state/state.service';
import * as _ from 'lodash';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { combineLatest } from 'rxjs';

@Component({
  selector: 'app-manage-entity',
  templateUrl: './manage-entity.component.html',
  styleUrls: ['./manage-entity.component.scss'],
  standalone: true,
  imports: [
    NzGridModule,
    NzSwitchModule,
    NzLayoutModule,
    NgIf,
    NgFor,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    NzInputModule
  ],
})
export class ManageEntityComponent implements OnInit {
  _createform = inject(UntypedFormBuilder);
  appStateService = inject(AppstateService);
  entityForm!: FormGroup;
  tab: any;
  meta_data: any;
  entityNameError: boolean = false;
  displayNameError: boolean = false;
  isEdit: boolean = false;
  editIndex: any;
  draggedColumnIndex: any;
  modalService = inject(NzModalService);
  stateService = inject(StateService);
  _nt = inject(NotificationService);
  popup1:any
  ngOnInit(): void {
    this.initiateEntityForm();
    this.meta_data = this.appStateService.get_meta_data(
      this.appStateService.getCurrentOrganization().router_org_value,
      this.appStateService.getCurrentProcess().router_process_value
    );
    
    this.tab = _.sortBy(this.meta_data?.entities.map((e:any)=>({...e,index:Number(e.index)})), 'index');

  }
  initiateEntityForm() {
    this.entityForm = this._createform.group({
      entityname: [null, [Validators.required]],
      displayname: [null, [Validators.required]],
      isPeople: [false, [Validators.required]],
      enableConversation: [false],
    });
  }
  checkExistingName(input: string, type: any) {
    if (type === 'Entity') {
      const inputValue = input.trim().toLowerCase().replace(/\s+/g, '');

      let isDuplicate = this.tab.some((el: any) => {
        const normalizedProcessName = el.name
          .trim()
          .toLowerCase()
          .replace(/\s+/g, '');
        return normalizedProcessName === inputValue;
      });
      this.entityNameError = isDuplicate;
    }

    if (type === 'Display') {
      const inputValue = input.trim().toLowerCase().replace(/\s+/g, '');

      let isDuplicate = this.tab.some((el: any) => {
        const normalizedProcessName = el.display_name
          .trim()
          .toLowerCase()
          .replace(/\s+/g, '');
        return normalizedProcessName === inputValue;
      });
      this.displayNameError = isDuplicate;
    }
  }
  saveEntityForm() {
    if (this.entityForm.valid) {
      if (this.displayNameError || this.entityNameError) {
        return;
      }
      if (this.isEdit) {
        this.tab[this.editIndex].name = this.tab[this.editIndex].name;
        this.tab[this.editIndex].is_people = this.entityForm.value.isPeople;
        this.tab[this.editIndex].display_name =
          this.entityForm.value.displayname;
        this.tab[this.editIndex].is_conv =
          this.entityForm.value.enableConversation;
        this.isEdit = true;
        this.tab?.map((e: any) => (e.exet = true));
      } else {
        let addEntity = {
          name: this.entityForm.value.entityname,
          index: `${this.tab.length}`,
          is_people: `${this.entityForm.value.isPeople}`,
          display_name: this.entityForm.value.displayname,
          hide_entity: `${false}`,
          is_conv: this.entityForm.value.enableConversation,
          exet: false,
        };
        // this.tab = [...this.tab, addEntity];
        this.tab?.map((e: any) => (e.exet = true));
        this.tab.push(addEntity);
      }
      this.initiateEntityForm();
      this.savePayload();
    } else {
      this.entityForm.markAllAsTouched();
    }
  }
  savePayload() {
    let data = this.tab.map((e: any) => {
      return {
        name: e.name,
        index: e.index,
        is_people: e.is_people,
        display_name: e.display_name,
        hide_entity: e.hide_entity,
        is_conv: e.is_conv,
        exet: e.exet !== undefined ? e.exet : true,
      };
    });
    this.saveModifyEntity(data)
  }
  saveModifyEntity(entity:any){
    
    let body = {
      org_id: this.appStateService?.getCurrentOrganization().org_id,
      process_id: this.appStateService?.getCurrentProcess()?.process_id,
      process_name: this.appStateService?.getCurrentProcess()?.process_name,
      "entity_data": entity
  }
    this.stateService.saveProcessEntityIndex(body).subscribe((res:any)=>{
      if(res){
    
        this._nt.show('Success', '', 'Entity updated sucessfully');
      }
    })
  }
  dragStartColumn(i: any, e: any) {
    this.draggedColumnIndex = i;
  }
  movingIndex: any;
  allowDrop(e: any, i: any) {
    this.movingIndex = i;
    e.preventDefault();
  }
  dropColumn(i: any, e: any) {
    this.arrayMove(this.tab, this.draggedColumnIndex, i);
    this.movingIndex = null;
  }
  arrayMove(arr: any[], from: any, to: any) {
    if (from < 0 || from >= arr.length || to < 0 || to >= arr.length) {
      return;
    }
    let aa = arr[from];
    let bb = arr[to];
    aa.index = `${to}`;
    bb.index = `${from}`;
    this.draggedColumnIndex = null;
    this.tab = _.sortBy(this.tab.map((e: any) => ({ ...e, exet: true })), 'index');
    this.savePayload();
  }
  editEntityName(value: any, index: any) {
    this.editIndex = index;
    this.isEdit = true;
    if (value) {
      this.entityForm.get('entityname')?.setValue(value.name);
      this.entityForm.get('displayname')?.setValue(value.display_name);
      this.entityForm
        .get('isPeople')
        ?.setValue(value.is_people === 'true' ? true : false);
      this.entityForm.get('enableConversation')?.setValue(value.is_conv);
      this.entityForm.get('entityname')?.disable();
    }
  }

  ngOnDestroy(): void {
    const currentOrganization = this.appStateService.getCurrentOrganization();
    const currentProcess = this.appStateService.getCurrentProcess();
    const payload = {
      org_id: currentOrganization.org_id,
      process_id: currentProcess.process_id
    };
    combineLatest([this.stateService.get_process_metadataNew(payload, currentProcess.process_name, currentProcess.org_process_id), this.stateService.getLayoutMetadata(payload)]).subscribe({
      next: (res: any) => {
        if (res) {
          this.appStateService.updateMetaDataAndLayout(res, currentOrganization.router_org_value, currentProcess.router_process_value);
          this.appStateService.updateMetaDataAndLayout(res, currentOrganization.router_org_value, currentProcess.router_process_value);
          this.appStateService.stateUpdate.next('checkRedirect')
          this.appStateService.call_initial_fetch.next('call_initial_api')
        }
      }
    })
    this.popup1?.close()
  }
  deleteEntity(data: any, i: number) {
    let datas = {
      deleteType: 'workFlow',
      text: `Are you sure you  want to delete ${data?.display_name} entity`,
    };
    this.popup1 = this.modalService.create({
      nzWidth: '394px',
      nzTitle: 'DELETE ENTITY',
      nzContent: DeleteColumnPopupComponent,
      nzMaskClosable: false,
      nzClosable: false,
      nzFooter: null,
      nzData: {
        data: datas,
      },
      nzClassName: 'popupheader',
    });

    this.popup1.afterClose.subscribe((res: any) => {
      if (res?.action === 'confirm') {
        let payload = {
          org_id: this.appStateService.getCurrentOrganization().org_id,
          process_id: this.appStateService.getCurrentProcess().process_id,
          entity_name: data.name,
        };
        this.stateService
          .removeUserProcessEntity(payload)
          .subscribe((res: any) => {
            if (res) {
              this.tab.splice(i, 1);
            }
          });
      }
    });
  }
  hideEntity(hideEntity: any, index: any) {
    this.tab[index].hide_entity = hideEntity === 'true' ? 'false' : 'true';
    this.tab?.map((e: any) => (e.exet = true));
    this.savePayload();
  }
  save() {
    // close pannel call meta data 
    this.initiateEntityForm();
  }
  cancel() {
    // close pannel call meta data 

    this.initiateEntityForm();
  }
}
