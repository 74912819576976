<div class="sidebar showSideBar" [hidden]="appStateService.is_mobile()">
  <div class="lb-bgcolor w80 text-center sidebar-wrapper" style="height: 100%">
    <div class="processo" (click)="home()">
      <img class="cPointer" class="side_logo" [src]="logo" />
    </div>
    <div
      class="mt-20"
      style="
        overflow-y: auto;
        max-height: 67dvh;
        z-index: 1000;
        position: relative;
      "
    >
      <ng-container *ngIf="routerData">
        <ng-container
          *ngFor="let item of processList()?.public___org_processes || []"
        >
          <div
            class="processContainers"
            (click)="selectedProcess(item)"
            [ngClass]="currentProcessSelected(item) ? 'bgColorBlue' : ''"
            nz-tooltip
            [nzTooltipOverlayClassName]="'toolTipContainer'"
            [nzTooltipTitle]="item.d_process_name"
            nzTooltipPlacement="right"
          >
            <img
              [src]="'data:image/svg+xml;base64,' + item.icon"
              style="width: 48px; height: 48px"
            />
          </div>
        </ng-container>
      </ng-container>
    </div>
    <div style="position: absolute; bottom: 5%; width: 100%; z-index: 10001">
      <img
      style="margin-bottom: 10px;"
      (click)="setting()"
      class="c_pointer"
      src="../../../../assets/images/settings.svg"
    />
      <div
        class="signout cPointer"
        nz-tooltip
        [nzTooltipOverlayClassName]="'signOutToolTipContainers'"
        [nzTooltipTitle]="'Sign Out'"
        nzTooltipPlacement="right"
      >
        <img
          src="../../../../assets/images/icons/power.png"
          (click)="signOut()"
        />
      </div>
      <div class="login">
        <a nz-dropdown [nzDropdownMenu]="menu" nzTrigger="click">
          <div class="profileLogoContainer fw-400 f-12">
            {{ firstName }}{{ lastName }}
          </div>
        </a>
        <nz-dropdown-menu #menu="nzDropdownMenu">
          <ul nz-menu class="sideBarContainer">
            <li
              style="padding: 10px 12px !important"
              nz-menu-item
              (click)="gotoOrganization()"
            >
              Organizations
            </li>
            <li
              style="padding: 10px 12px !important"
              nz-menu-item
              (click)="gotoProfileSettings()"
            >
              Profile Settings
            </li>
            <nz-divider style="margin: 0 !important; background: #00000030">
            </nz-divider>
            <li
              class="fw-600"
              style="cursor: default; padding: 10px 12px !important"
            >
              {{ fullName }}
            </li>
          </ul>
        </nz-dropdown-menu>
      </div>
    </div>
  </div>
</div>
<div [hidden]="!appStateService.is_mobile()">
  <div style="display: flex;justify-content: space-between;padding: 20px 20px 0 20px;align-items: center;">
    <img src="../../../../assets/images/processo-icon.svg" (click)="go_to('close')"/>
    <img src="../../../../assets/images/Cross.png" style="height: 21px;" (click)="go_to('close')"/>
  </div>
  <div>
    <ng-container
    *ngFor="let item of processList()?.public___org_processes || []"
  >
    <div
      class="processContainers"
      (click)="selectedProcess(item)"
      [ngClass]="currentProcessSelected(item) ? 'bgColorBlue' : ''"
      nz-tooltip
      [nzTooltipOverlayClassName]="'toolTipContainer'"
      [nzTooltipTitle]="item.d_process_name"
      nzTooltipPlacement="right"
    >
    <div style="display: flex;align-items: center;column-gap: 10px;">
      <img
      [src]="'data:image/svg+xml;base64,' + item.icon"
      style="width: 48px; height: 48px"
    /> <div class="f-11 fw-400 berlinBluecolor">{{ item.d_process_name }}</div>
    </div>
    </div>
  </ng-container>
  </div>
</div>
