import { Component, inject, Input, OnChanges, OnInit, Signal, signal } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppstateService } from '@processo/shared/state/appstate.service';
import { orderBy } from 'lodash';
import { toObservable } from '@angular/core/rxjs-interop';
import { delay } from 'rxjs';
import { extractEntityName } from '@processo/shared/state/appstate.logic';
import * as _ from 'lodash';
@Component({
  selector: 'app-header-nav-bar',
  templateUrl: './header-nav-bar.component.html',
  styleUrls: ['./header-nav-bar.component.scss']
})
export class HeaderNavBarComponent implements OnInit  {
  appStateService = inject(AppstateService);
  _router = inject(ActivatedRoute);
  router = inject(Router);
  params:any = signal({});
  entities!:Signal<any>
  index =0
  constructor(){
    this._router.params.subscribe((params:any)=>{
    this.params.set(params);
    this.entities=this.appStateService.getEntityTableHeaders(this.params().organization_name,
      this.params().process_name);
    })
    this.appStateService.stateUpdate.pipe().subscribe((response) => {

      this.entities = this.appStateService.getEntityTableHeaders(this.params().organization_name,
        this.params().process_name);
      extractEntityName(decodeURIComponent(this.router.url), 'view').then((res) => {
        
      //   if (response =='checkRedirect') {
      //     const index = _.findIndex(_.sortBy(this.entities().map((e: any) => ({ ...e, index: Number(e.index) })), 'index'), (e: any) => e.display_name === res);

      //     if (this.entities()[index]?.hide_entity === 'false') {
      //       this.router.navigateByUrl(`/org/${this.params().organization_name}/process/${this.params().process_name}/view/${this.entities()[index - 1] || this.entities()[0]||'Dashboard'}`)
      //     }
      //  }
      })
    })

  }
  changeTab(tab:any){
    this.router.navigateByUrl(`/org/${this.params().organization_name}/process/${this.params().process_name}/view/${tab.display_name}`)

  }
  findTabIndex(entities:any){
      const split = decodeURIComponent(this.router.url).split('/').reverse();
    const displayName = (split.includes('edit') ? split[1] : split[0])?.split('?')[0];
    if (displayName === 'my-approval') {
        return 9999;
    }
    if (displayName === 'Dashboard') {
      return -1;
    }

      const index = entities.findIndex((tab:any) => tab.display_name === displayName);

      return index;
  }
  ngOnInit(): void {




  }


  ngAfterViewInit(){
    // this.router.navigateByUrl(`/org/${this.params().organization_name}/process/${this.params().process_name}/${this.entities()[0]?.display_name}`)
  }

}
