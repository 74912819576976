<!-- <ng-container *ngIf="entities().length > 0">
  <nz-tabset [nzTabPosition]="'top'" [nzSelectedIndex]="findTabIndex(entities())">
    <ng-container *ngFor="let tab of entities(); let i = index">
      <nz-tab [nzTitle]="tab.display_name" (nzClick)="changeTab(tab, i)">
      </nz-tab>
    </ng-container>
    <nz-tab [nzTitle]="''"></nz-tab>
  </nz-tabset>
</ng-container> -->
<ng-container *ngIf="entities().length > 0">
  <ul nz-menu nzMode="horizontal">
    <ng-container *ngFor="let tab of entities(); let i = index">
      <ng-container *ngIf="tab?.display_name">
        <li class="f-12" nz-menu-item (click)="changeTab(tab)" [nzSelected]="i ===0">{{tab.display_name}}</li>
      </ng-container>
      <ng-container *ngIf="tab?.gd_name">
        <li class="f-12" nz-submenu [nzTitle]="tab.gd_name">
          <!-- <img src="../../../../assets/images/icons/down_arrow.svg"> -->
          <ul>
            <ng-container *ngFor="let item of tab.child_data">
              <li class="f-12" nz-menu-item (click)="changeTab(item)">{{item.display_name}}</li>
            </ng-container>
            
          </ul>
        </li>
      </ng-container>
    </ng-container>
    
  </ul>
</ng-container>





<ng-template #skeletonText>
  <div
            class="ghost"
            style="height: 20px; margin: auto; width: 100px"
          ></div>
</ng-template>
